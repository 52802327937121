import i18n from 'i18n'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const Footer = () => {
  const { t } = useTranslation('common')
  const { language } = i18n

  return (
    <footer tw="bg-gray-900 border-t border-gray-800 min-h-14">
      <div tw="px-6 lg:pl-[90px] m-auto flex flex-col xs:flex-row gap-2 xs:gap-0 py-4 justify-between items-center text-gray-100 text-2xs">
        <div tw="grid grid-flow-col gap-4">
          <a
            target="_blank"
            href={`https://refspace.com/${language}/terms-of-use`}
            rel="noreferrer"
          >
            {t('footer.rules')}
          </a>
          <a
            target="_blank"
            href={`https://refspace.com/${language}/privacy-policy`}
            rel="noreferrer"
          >
            {t('footer.privacyPolicy')}
          </a>
        </div>
        <p tw="text-2xs">© {new Date().getFullYear()} REFSPACE LTD</p>
      </div>
    </footer>
  )
}

export { Footer }
