import { faChevronDown, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeLanguage } from 'components/common/changeLanguage'
import { ChangePasswordButton } from 'components/dialog/changePasswordDialog/changePasswordButton'
import { Dialog } from 'components/dialog/dialogBase/dialog'
import { DialogRef } from 'components/dialog/dialogBase/dialogProvider'
import { LogoutDialog } from 'components/dialog/logoutDialog/logoutDialog'
import { useAuth } from 'context/authProvider'
import { throttle } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'
import ContactUs from './contactUs'
import { EmailMenu } from './emailMenu'
import { Hamburger } from './hamburger'
import { Notification } from './notification'
import css from './styles.fixNavBar.module.css'
/** @jsxImportSource @emotion/react */

const TopBarAuth = ({
  handleToggleIsOpenMobileMenu,
  profilePhoto,
  isOpenMobileMenu,
  isViewOnboarding
}: {
  handleToggleIsOpenMobileMenu: () => void
  profilePhoto?: string
  isOpenMobileMenu: boolean
  isViewOnboarding: boolean
}) => {
  const { pathname } = useLocation()
  const { t: tCommon } = useTranslation('common')
  const { refreshUser, user } = useAuth()
  const [isOpenEmailMenu, setIsOpenEmailMenu] = useState(false)
  const [isOpenNotificationMenu, setIsOpenNotificationMenu] = useState(false)
  const changePasswordDialogRef = useRef<DialogRef>(null)
  const logoutDialogRef = useRef<DialogRef>(null)

  const isDisableRefreshUser =
    (user?.userType === 'company' && user.companyStatus === 'stripeActivated') ||
    user?.userType === 'creator' ||
    user?.companyStatus === 'active' ||
    user?.creatorStatus === 'active'
  const [isRefetch, setIsRefetch] = useState<boolean>(true)

  const timeRefetch = pathname === '/profile' ? 20000 : 10000

  const throttledRefreshUser = throttle(() => {
    if (isRefetch && user?.userType !== 'fan') {
      refreshUser()
      if (isDisableRefreshUser) {
        setIsRefetch(false)
      }
    }
  }, timeRefetch)

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (isRefetch && user?.userType !== 'fan' && !isDisableRefreshUser) {
      intervalId = setInterval(throttledRefreshUser, timeRefetch)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isRefetch, isDisableRefreshUser, user?.userType, throttledRefreshUser])

  const isViewButtonChangeRole = user?.userType === 'fan'

  return (
    <div
      tw="w-full fixed z-50 top-0 flex bg-gray-800 drop-shadow-sm min-h-14  items-center justify-between"
      css={[isViewOnboarding && tw`pointer-events-none`]}
    >
      <Logo />
      <div
        className={css.fixNavBar}
        tw="w-full flex justify-between items-center sm:pl-4 pl-3 pr-4 xs:pr-6 "
      >
        <div tw=" justify-end items-center h-16 w-28 md:flex hidden">
          <img src="/images/logo-panel.svg" alt="logo-panel" />
        </div>
        {process.env.REACT_APP_IS_STAGING == 'true' && (
          <p tw="text-xs font-semibold text-warning">STAGING</p>
        )}

        <div tw="flex items-center w-full xs:w-auto justify-end">
          <ChangeLanguage />
          <Dialog
            title={tCommon('buttons.contact')}
            trigger={<FontAwesomeIcon icon={faEnvelope} tw="h-[22px] px-3" />}
          >
            <ContactUs />
          </Dialog>
          {user?.userType !== 'fan' && (
            <Notification
              isOpenNotificationMenu={isOpenNotificationMenu}
              setIsOpenNotificationMenu={setIsOpenNotificationMenu}
            />
          )}
          {user?.isEmailVerified && (
            <button
              onClick={() => setIsOpenEmailMenu(!isOpenEmailMenu)}
              tw="hidden xl:grid gap-3 grid-flow-col items-center"
            >
              <p tw="text-xs font-semibold">{user?.email}</p>
              {(user?.creatorStatus === 'pending' || user?.creatorStatus === 'active') &&
                profilePhoto && (
                  <img
                    src={profilePhoto}
                    tw="rounded-full"
                    width="40"
                    height="40"
                    alt="thumbnail"
                  />
                )}
              <FontAwesomeIcon icon={faChevronDown} tw="h-4 text-primary" />
            </button>
          )}
          <Hamburger
            handleToggleIsOpenMobileMenu={handleToggleIsOpenMobileMenu}
            isOpenMobileMenu={isOpenMobileMenu}
          />
          {isOpenEmailMenu && (
            <EmailMenu
              isActiveChangeRole={isViewButtonChangeRole}
              setIsOpenEmailMenu={setIsOpenEmailMenu}
              changePasswordDialogRef={changePasswordDialogRef}
              logoutDialogRef={logoutDialogRef}
            />
          )}
        </div>
      </div>
      <ChangePasswordButton forwardedRef={changePasswordDialogRef} />
      <LogoutDialog forwardedRef={logoutDialogRef} />
    </div>
  )
}

const Logo = () => (
  <div tw="flex justify-center items-center h-14 xl:h-16 md:bg-primary min-w-16 md:max-w-[64px] max-w-[110px] w-full">
    <img src="/images/logo.svg" alt="logo" tw="md:block hidden" />
    <img src="/images/logo-panel.svg" alt="logo" tw="md:hidden block pl-2.5" />
  </div>
)

export { Logo, TopBarAuth }
