import { config } from '@fortawesome/fontawesome-svg-core'
import '@reach/dialog/styles.css'
import { createGlobalStyle } from 'styled-components'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'
/** @jsxImportSource @emotion/react */

config.autoAddCss = false

const CustomStyles = createGlobalStyle`  

  .fa-primary {
    color:var(--fa-primary-color)
  }
  
  .grecaptcha-badge {
    opacity: 0;
  }

  .fa-secondary {
    color:var(--fa-secondary-color);
    opacity:var(--fa-secondary-opacity)
  }

  .dialog__video {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .dialog__video--carusel { grid-area: 1 / 1 / 6 / 4; }
  .dialog__video--description { grid-area: 1 / 4 / 6 / 6; }

  .dialog__video--carusel img {
    max-width: 100%;
    margin: auto;
    object-fit: contain;
    max-height: calc(100vh - 600px)
  }

  .dialog__video--carusel video { 
    max-height: calc(100vh - 600px)
  }

  .dialog__video--carusel .carousel .control-dots {
    display:none
  }

  .dialog__video--carusel .carousel-root {
    max-width: 100vw;
  }

  .dialog__video--carusel .carousel .thumb.selected {
    border: 3px solid #46CF73;
  }

  .dialog__video--carusel .carousel .thumb:hover {
    border: 3px solid #FFF;
  }

  .dialog__video--carusel .carousel .thumb img { 
    object-fit: cover;
    height: 70px;
  }
  .dialog__video--carusel .carousel .thumb { 
    border: 3px solid #484C52; 
    min-height: 80px;
  }

  .dialog__video--carusel .carousel .control-prev.control-arrow:hover:before {
    border-right: 8px solid #46CF73;
  }

  .dialog__video--carusel .carousel .control-next.control-arrow:hover:before {
    border-left: 8px solid #46CF73;
  }  

  .dialog__video--carusel .carousel .slide.selected {
    z-index:1
  }

  .dialog__video--carusel .carousel .slide img {
    width:99%
  }

  @media only screen and (max-width: 475px) {

    .dialog__video .dialog__video--carusel video {
      max-height: initial;
    }

    .dialog__video {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }

    .dialog__video--carusel { grid-area: 1 / 1 / 3 / 6; }
    .dialog__video--description { grid-area: 3 / 1 / 5 / 6; }

  }

  .loading:after {
  content: ' . ';
  animation: dots 1.5s steps(5, end) infinite;}

  @keyframes dots {
    0%, 20% {
      color: rgba(70,207,115,1);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: rgba(70,207,115,1);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 rgba(70,207,115,1),
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
  }

	body {
    -webkit-tap-highlight-color: transparent;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    ${tw`antialiased bg-gray-900 min-h-screen`}
    overflow:hidden;
   
  }

  html {
    scrollbar-width: thin; 
  }

  hr {
    border: 0;
    border-top: 1px solid #373A3E;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: black;
  }
  
  scrollbar-width: 8px;
  
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #484C52;
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    color: #F5F5F5;
    svg,
    i {
    color: #F5F5F5;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  ::-moz-selection { 
    color: #F5F5F5;
    background: #46CF73;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #F5F5F5;
    -webkit-box-shadow: 0 0 0px 1000px #202225 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ::selection {
    color: #F5F5F5;
    background: #46CF73;
  }

  
 

  .menu__button {
    z-index: 5;
    display: block;
    position: relative;
    width: 22px !important;
    height: 18px !important;
    cursor: pointer;
 }

  .menu__button.menu__button-active span {
    transform: scale(0) translate(0, -50%);
  }

  .menu__button.menu__button-active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }

  .menu__button.menu__button-active::after {
    bottom: 47.9%;
    transform: rotate(47deg) translate(0, 50%);
  }

  .menu__button span,
  .menu__button::after,
  .menu__button::before {
    left: 0;
    position: absolute;
    height: 12%;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: #ffffff;
  }

  .menu__button::after,
  .menu__button::before {
    content: '';
  }

  .menu__button::before {
    top: 0;
  }

  .menu__button::after {
    bottom: 0;
  }

  .menu__button span {
    top: 50%;
    transform: scale(1) translate(0, -50%);
  }
    .slick-list{
    overflow:visible;
    }
    

    

`
const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles
