import {
  createContext,
  Dispatch,
  forwardRef,
  ReactNode,
  SetStateAction,
  useContext,
  useImperativeHandle,
  useState
} from 'react'

export type DialogRef =
  | {
      setIsOpen: (value: boolean) => void
    }
  | null
  | undefined

const DialogContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>] | null>(null)

interface DialogProps {
  children: ReactNode
}

// eslint-disable-next-line react/display-name
const DialogProvider = forwardRef<DialogRef, DialogProps>(({ children }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    setIsOpen(value: boolean) {
      setIsOpen(value)
    }
  }))

  return <DialogContext.Provider value={[isOpen, setIsOpen]}>{children}</DialogContext.Provider>
})

const useDialogContext = () => {
  const value = useContext(DialogContext)
  if (!value) {
    throw new Error('use useDialogContext within Dialog')
  }
  
  return value
}

export { DialogProvider, useDialogContext, DialogContext }
