import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LanguageEnum } from 'models/api/languageEnum'
import { ICONS } from 'models/app/icons'
import React, { useState } from 'react'
import { FieldError } from 'react-hook-form'
import { css } from 'styled-components'
import tw from 'twin.macro'
import { FieldTooltipError } from '../fieldTooltipError'
import { Label } from '../label'
/** @jsxImportSource @emotion/react */

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
  isNarrow?: boolean
  language?: LanguageEnum
  error?: FieldError
  count?: number
  withPercent?: boolean
  hideMax?: boolean
  isDisableColorInputTextValue?: boolean
  isTranslateStepDisable?: boolean
  isTranslateStep?: boolean
  handleRemoveItemFieldArray?: () => void
}

const focusStyles = css`
  &:focus ~ .icon-name {
    ${tw`text-primary`}
  }
`

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      label,
      id,
      type,
      language,
      className,
      isDisableColorInputTextValue,
      isTranslateStepDisable,
      isNarrow = false,
      hideMax,
      handleRemoveItemFieldArray,
      isTranslateStep,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false)
    const [inputType, setType] = useState(type)
    return (
      <div tw="w-full min-w-0" className={className}>
        {label && <Label language={language}>{label}</Label>}

        <div tw="relative w-full">
          <input
            id={id || props.name}
            ref={ref}
            type={inputType}
            tw="h-12 max-w-full text-2xs rounded-md bg-gray-500  text-gray-100 px-3 focus:outline-none border border-gray-600 focus:border-primary placeholder-gray-100"
            css={[
              focusStyles,
              isDisableColorInputTextValue && tw`text-gray-200`,
              error && tw`border-error text-error pr-11`,
              (type === 'password' || props?.withPercent) && tw`pr-11`,
              error && (type === 'password' || props?.withPercent) && tw`pr-16`,
              isTranslateStepDisable && tw`bg-gray-800 pointer-events-none`,
              isNarrow && tw`h-10`,
              css`
                width: -moz-available;
                width: -webkit-fill-available;
                width: fill-available;
                ::placeholder {
                  ${tw`text-placeholder`};
                  opacity: 1; /* Firefox */
                }
              `,
              isDisableColorInputTextValue &&
                css`
                  ::placeholder {
                    color: #aaaaaa;
                    opacity: 1; /* Firefox */
                  }
                `
            ]}
            {...props}
          />

          <div tw="absolute right-4 top-0 bottom-0 text-lg grid grid-flow-col gap-3 justify-center items-center z-30">
            {!!handleRemoveItemFieldArray && !isTranslateStep && (
              <button
                type="button"
                tw="text-white w-4 text-lg flex justify-center items-start cursor-pointer"
                onClick={() => handleRemoveItemFieldArray?.()}
              >
                <FontAwesomeIcon tw="h-4" icon={ICONS.xmark} />
              </button>
            )}
            {type === 'password' && (
              <button
                type="button"
                tw="text-primary w-4 text-lg flex justify-center items-start cursor-pointer"
                onClick={() => {
                  setShowPassword(!showPassword)
                  setType(showPassword ? 'password' : 'text')
                }}
              >
                <FontAwesomeIcon tw="h-4" icon={showPassword ? ICONS.eye : ICONS['eye-slash']} />
              </button>
            )}
            {props?.withPercent && (
              <p css={[isDisableColorInputTextValue ? tw`text-gray-200 text-2xs` : tw`text-2xs`]}>
                %
              </p>
            )}
            {props.maxLength &&
              !hideMax &&
              !isTranslateStepDisable &&
              !handleRemoveItemFieldArray && (
                <span tw="text-gray-200 text-2xs">
                  {`${props.value?.toString().length}/${props.maxLength}`}
                </span>
              )}
            <FieldTooltipError
              error={props.value == 0 && error?.type == 'min' ? { ...error, type: 'zero' } : error}
              maxLength={props.maxLength}
              minLength={props.minLength}
            />
          </div>
        </div>
      </div>
    )
  }
)

Input.displayName = 'AppInput'

export { Input }
